.align_button {
  margin-left: 1%;
}
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  #container {
    all: unset;
  }
  #menu {
    display: none;
  }
  body {
    visibility: hidden;
  }
  #btn-print {
    display: none;
  }
  #btn-exit {
    display: none;
  }
  .printable {
    visibility: initial;
  }
}
