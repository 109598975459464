.pdfBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  //margin: 1rem;
  //justify-content: flex-start;
}

.page-header {
  display: flex;
  flex-direction: row;
}

.page-one {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}

.page-header-indicator {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.top-table {
  width: 45%;
  margin-left: 3%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;
  flex-wrap: wrap;
}
.bottom-table {
  width: 45%;
  margin-left: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;

  flex-wrap: wrap;
}

.top-table-header {
  width: 99%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.top-table-line {
  margin-left: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.t_text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
}

.pdf_span {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
}

.pdf_p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
}

.headerComplete {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-around;
  padding: 1px;
  margin-bottom: 1px;
  width: auto;
}

.headerLogo {
  margin: 2px;
}

.logo-iso {
  width: 91px;
  max-width: 91px;
}

@media print {
  .headerLogoIso {
    width: 1cm;
    max-width: 1cm;
  }
  .logo-iso {
    width: 1cm;
  }
}

.name {
  grid-area: company-name;
}

.budget-number {
  grid-area: budget-number;
  font-weight: bold;
  font-size: 1.2rem;
}

.budget-text {
  grid-area: budget-text;
}

.client {
  grid-area: client;
}

.client-data {
  grid-area: client-data;
}

.budget-title {
  font-weight: bold;
  font-size: 1.3rem;
  text-decoration: underline;
  text-align: center;
}

.warning-text-title {
  font-size: 15px;
  text-align: center;
  color: red;
  align-self: center;
  font-family: Arial, Helvetica, sans-serif;
}

.disclaimer-title {
  font-size: 13px;
  text-align: justify;
  font-family: Arial, Helvetica, sans-serif;
}
.disclaimer-text {
  font-size: 10px;
  text-align: justify;
  font-family: Arial, Helvetica, sans-serif;
}

.budget-header {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto;
  margin: 2px;
  border-bottom: solid 1px grey;
  grid-template-areas:
    "budget-text budget-number budget-number budget-number "
    "client client-data client-data client-data ";
}

.headerData {
  display: grid;
  grid-template-columns: 1.75fr 1fr;
  grid-template-rows: auto;
  column-gap: 1px;
  row-gap: 1px;
  grid-template-areas: "company-name company-name company-name company-name";
}

div {
  padding: 2px;
}

.warning_span {
  color: red;
}

.lateral_text {
  font-size: 12px;
  margin-bottom: 0;
  font-family: Arial, Helvetica, sans-serif;
}
.observation_text {
  font-size: 12px;
  margin-bottom: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.text_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  margin-right: 50px;
}
.text_total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.observation_box {
  border: 1px solid rgb(160, 160, 160);
  border-radius: 4px;
  min-height: 40px;
}
.table_text_sub {
  font-size: 12px;
  margin: 0;
}

.list_align {
  margin-left: 2%;
  line-height: 15px;
}

.headerText {
  display: flex;
  flex-direction: row;
}

@media print {
  .topText {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8px;
    line-height: 4px;
  }
  .headerText {
    line-height: 4px;
  }

  .bodyText {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
  }
  .budget-number {
    font-size: 14px;
  }
  .outline {
    width: 100%;
    //height: 980px;
    //margin: auto;
    margin: 0 !important;
    justify-content: flex-start;
  }
  .list_text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8px;
  }
  .page-one {
    page-break-after: always;
    width: 98%;
    height: 95%;
    position: absolute;
    margin-top: 0px !important;
  }
  .print-outline {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 98%;
    height: 98%;
    border: 1px solid gray;
    z-index: 9999; /* Ensure it's on top of other content */
  }
  table {
    width: 100%;
    margin: 0;
  }
  .combine-text {
    white-space: nowrap;
  }
}
